import { useMemo } from 'react'

import { getDisplayLocation } from 'domain/orders/helpers'
import { getFieldName } from 'domain/createOrder/shared'
import {
  AddressFields,
  MaskedField,
  NumberField,
  RadioGroupField,
  SelectField,
  SplittableField,
  TextAreaField,
  TextField,
  UploadField,
  YearField,
} from 'domain/servicesCBRdoc/fields'
import { CPFOrCNPJInput, DateInput } from 'ui'

import { useGetKit, useGetRegistreeForm, useGetServiceForm, useUpdateServiceDetailsForm } from '../../state'
import { KitCommonFormServiceConfig, KitFieldConfig } from '../../types'
import KitDontKnowBookPage from './KitDontKnowBookPage'
import OtherServiceFields from './OtherServiceFields'

interface Props {
  config: KitCommonFormServiceConfig
}

export default function DetailsServiceFields({ config }: Props) {
  const kit = useGetKit()
  const { kitItem, fields } = config
  const serviceForm = useGetServiceForm(kitItem.id)
  const location = getDisplayLocation(serviceForm)

  const kitItemHasExtraFields = !!kit.items.find(item => item.id === kitItem.id)
  return (
    <div className="mt-8">
      <h3 className="font-bold text-lg mb-1">{kitItem.service.name}</h3>

      {!!location && <p className="text-gray-600 text-sm mb-4">{location}</p>}

      <ServiceFields fields={fields} />

      {kitItemHasExtraFields && <OtherServiceFields key={kitItem.id} kitItem={kitItem} />}
    </div>
  )
}

function ServiceFields({ fields }: { fields: KitFieldConfig[] }) {
  const serviceFields = useMemo(() => {
    const hasAddress = fields.find(field => field.name === 'endereco')

    if (hasAddress) {
      return fields.filter(field => field.name !== 'cep')
    }

    return fields
  }, [fields])

  return (
    <>
      {serviceFields.map(field => (
        <ServiceField key={field.name} field={field} />
      ))}
    </>
  )
}

function ServiceField({ field }: { field: KitFieldConfig }) {
  const { dataScope, kind, mask, name, options, showIf } = field
  const kitItem = field.kitItem!
  const itemId = kitItem!.id
  const serviceFormData = useGetServiceForm(itemId)
  const registreeFields = useGetRegistreeForm()

  if (showIf && (!showIf(serviceFormData) || (dataScope === 'registree' && registreeFields[name]))) {
    return null
  }

  if (kind === 'dontKnowBookPage') {
    return <KitDontKnowBookPage itemId={itemId} />
  }

  if (kind === 'fullAddress') {
    return <AddressFields itemId={itemId} />
  }

  if (kind === 'radio' && options) {
    return <RadioGroupField field={field} itemId={itemId} />
  }

  if (kind === 'select' && options) {
    return <ServiceSelectField field={field} itemId={itemId} />
  }

  if (kind === 'splittable') {
    return <SplittableField field={field} itemId={itemId} />
  }

  if (kind === 'year') {
    return <YearField field={field} itemId={itemId} />
  }

  if (mask) {
    return <MaskedField field={field} itemId={itemId} />
  }

  if (kind === 'text') {
    return <TextField field={field} itemId={itemId} />
  }

  if (kind === 'number') {
    return <NumberField field={field} itemId={itemId} />
  }

  if (kind === 'multiline') {
    return <TextAreaField field={field} itemId={itemId} />
  }

  if (kind === 'upload') {
    return <UploadField field={field} itemId={itemId} />
  }

  if (kind === 'date') {
    return (
      <DateInput label={field.label} name={getFieldName(field.name, itemId)} required={!field.optional} />
    )
  }

  if (kind === 'cpfOrCnpj') {
    return <CPFOrCNPJInput itemId={itemId} name={field.name} required={!field.optional} />
  }

  if (kind === 'name') {
    return <TextField field={field} itemId={itemId} />
  }

  return null
}

function ServiceSelectField({ field, itemId }: { field: KitFieldConfig; itemId: number }) {
  const updateForm = useUpdateServiceDetailsForm(itemId)

  return (
    <SelectField
      field={field}
      onChange={value => updateForm({ [field.name]: value })}
      itemId={itemId}
      labelInValue
    />
  )
}

import React from 'react'

import { AIDefaultQuestion, AIQuestion } from 'domain/openAI/types'
import { Clipboard } from 'ui'

interface Props {
  questions: AIQuestion[] | AIDefaultQuestion[] | undefined | null
}

export default function OpenAIQuestions({ questions }: Props) {
  if (!questions) return null

  return (
    <div className="empty:hidden w-full bg-white p-4 rounded">
      {questions.map((question, index) => (
        <Question key={index} question={question} />
      ))}
    </div>
  )
}

function Question({ question }: { question: AIQuestion | AIDefaultQuestion }) {
  const answer = (question as AIQuestion).answer

  if (!answer) return null

  return (
    <div className="flex gap-4 py-4">
      <div className="font-bold text-sm text-secondary-300 flex items-center w-48">{question.questionToShow}</div>
      {answer ? (
        <Clipboard
          text={answer}
          className="underline block min-w-1/2 text-left pointer leading-4 m-0"
        />
      ) : (
        <span className="block min-w-1/2 text-sm text-secondary-500 font-semibold">
          {(question as AIDefaultQuestion).answerPlaceholder || question.questionToShow}
        </span>
      )}
    </div>
  )
}

import { Icon } from 'ui'
import { getEstimatedDateStr } from 'domain/orders/helpers'
import { OrderItemListing } from 'domain/orderItem/types'
import { OrderOverview, OrderStatuses } from 'domain/orders/types'
import OrderOverviewGroups from './OrderOverviewGroups'
import { Tag } from 'antd'
import { useCurrentCustomer } from 'domain/customers'

interface Props {
  order: OrderOverview
  orderItems: OrderItemListing[]
}

const OrderOverviewDetails = ({ order, orderItems }: Props) => {
  const estimatedDateStr = getEstimatedDateStr(order.estimatedDeliveryAt, order.statusCode)
  const status = OrderStatuses[order.statusCode]
  const { showShippingInfoOnOrders } = useCurrentCustomer()

  return (
    <div className="flex bg-white rounded py-5 px-7 mx-7 gap-9">
      <div>
        <p className="text-secondary-300 font-semibold">Status</p>
        <div className="flex gap-2 items-center">
          <Tag className="text-white m-0" color={status.colorHexa} bordered={false}>
            {order.totalItems}
          </Tag>
          <p className="text-sm">{status.name}</p>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="flex gap-2 text-secondary-300">
          <Icon name="ai-calendar" size={24} />
          <p className="font-semibold">Data de solicitação</p>
        </div>
        <p className="text-sm ml-8">{order.createdAtDisplay}</p>
      </div>

      <div className="flex flex-col">
        <div className="flex gap-2 text-secondary-300">
          <Icon name="ai-user" size={24} />
          <p className="font-semibold">Solicitante</p>
        </div>
        <p className="text-sm ml-8">{order.user.name}</p>
      </div>

      <div className="flex flex-col">
        <div className="flex gap-2 text-secondary-300">
          <Icon name="invoice" size={24} />
          <p className="font-semibold">Valor da compra</p>
        </div>
        <p className="text-sm ml-8">{order.priceDisplay}</p>
      </div>

      {!!showShippingInfoOnOrders && (
        <div className="flex flex-col">
          <div className="flex gap-2 text-secondary-300">
            <Icon name="ai-calendar" size={24} />
            <p className="font-semibold">Prazo estimado</p>
          </div>
          <p className="text-sm ml-8">{estimatedDateStr}</p>
        </div>
      )}

      <div className="flex flex-col">
        <div className="flex gap-2 text-secondary-300">
          <Icon name="group" size={24} />
          <p className="font-semibold">Grupo</p>
        </div>
        <OrderOverviewGroups orderItems={orderItems} />
      </div>
    </div>
  )
}

export default OrderOverviewDetails

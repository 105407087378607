import React from 'react'

import { ErrorBoundary } from 'ui'
import PageHeader, { PageHeaderProps } from './PageHeader'

export interface BasicLayoutProps extends PageHeaderProps {
  children: React.ReactNode
  header?: React.ReactNode
  pageTitle?: React.ReactNode
  innerClass?: string
}

export default function BasicLayout({ children, pageTitle, innerClass, header, ...props }: BasicLayoutProps) {
  return (
    <>
      <PageHeader title={pageTitle} {...props}>
        {header}
      </PageHeader>

      <div className={`flex flex-1 flex-wrap relative mask-text ${innerClass}`}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
    </>
  )
}

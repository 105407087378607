import { LOCAL } from 'api/network/config'
import { convertToSelectedService } from 'domain/createOrder/document'
import { convertExplorerItem, convertOCR } from 'domain/myFiles'
import { convertCreateOrderModel } from 'domain/openAI/models'
import { convertResearchResults } from 'domain/orderItem/research'
import { OrderItem, OrderItemListing, OrderItemSummary, SelectedService } from 'domain/orderItem/types'
import { convertOrder } from 'domain/orders'
import { ResultCode, StatusCode } from 'domain/orders/types'
import { LocationInfoAPI } from 'domain/orders/typesAPI'
import { convertLocationToSelectOption, getServiceConfig } from 'domain/servicesCBRdoc'
import {
  CertificateCode,
  ResearchCode,
  ServiceAPI,
  ServiceCode,
  ServiceType,
} from 'domain/servicesCBRdoc/types'
import { displayDate, stringToDate } from 'utils/dateTime'
import { formatCNPJ, formatCPF, formatMoney } from 'utils/formatters'

import { ExtractedSummary, OrderItemAPI } from '../../typesAPI'

export function convertOrderItem(originalItem: OrderItemAPI, isPostPaid: boolean) {
  const {
    annotations,
    can_accept_additional_information,
    detailed_service_data,
    explorer_item,
    finished_at,
    groups,
    has_extras,
    index_in_purchase,
    next_order_id_same_purchase,
    ocr,
    previous_order_id_same_purchase,
    purchase,
    refundable,
    refundable_value,
    service,
    service_category,
    status_details,
  } = originalItem

  const { cpf, cnpj } = detailed_service_data ?? {}

  const orderItem: OrderItem = {
    ...convertOrderItemListing(originalItem, isPostPaid),
    hasExtras: has_extras,
    annotations,
    canAcceptAdditionalInformation: can_accept_additional_information,
    canAskRefund: refundable && !isPostPaid,
    cpfOrCnpj: cpf ? formatCPF(cpf) : cnpj ? formatCNPJ(cnpj) : '',
    finishedAt: finished_at,
    groups: groups ?? [],
    ocr: convertOCR(ocr),
    order: convertOrder(purchase, isPostPaid),
    refundValue: refundable_value,
    refundable: refundable,
    rejectedReason: status_details,
    serviceCategory: service_category,
  }

  if (service.ai_default_model) {
    orderItem.service.aiDefaultModel = convertCreateOrderModel(service.ai_default_model)
  }

  if (explorer_item) {
    orderItem.explorerItem = convertExplorerItem({
      ...explorer_item,
      ocr: ocr ? ocr : undefined,
    })
  }

  if (purchase.orders_count) {
    orderItem.navigation = {
      currentIndex: index_in_purchase,
      nextId: next_order_id_same_purchase,
      previousId: previous_order_id_same_purchase,
      totalItems: purchase.orders_count,
    }
  }

  const hasResultsOrder = orderItem.service.type === ServiceType.RESEARCH || !!orderItem.extractSummary
  if (hasResultsOrder) {
    return convertResearchResults(originalItem, orderItem)
  }

  return orderItem
}

// Formato usado na listagem
export function convertOrderItemListing(originalItem: OrderItemAPI, isPostPaid: boolean) {
  const {
    detailed_service_data,
    estimated_at,
    extracted_summary,
    file_preview_url,
    groups,
    has_ai_extracted_data,
    has_ai_analysis_pending,
    has_consumed_ai_free_analysis,
    has_extras,
    imported_at,
    is_expired,
    last_status_change_at,
    location_info,
    ocr,
    purchase,
    register,
    service,
    valid_until,
    verification_code,
  } = originalItem

  const orderItem: OrderItemListing = {
    ...convertOrderItemSummary(originalItem, isPostPaid),
    estimatedDeliveryAt: stringToDate(estimated_at)!,
    hasExtras: has_extras,
    fileURL: file_preview_url,
    groups: groups ?? [],
    hasConsumedAiFreeAnalysis: has_consumed_ai_free_analysis,
    hasAiExtractData: has_ai_extracted_data,
    hasAiAnalysisPending: has_ai_analysis_pending,
    isExpired: is_expired,
    isFromPassportWithoutAllData: !!imported_at && verification_code === 'importado_documento_brasil',
    locationInfo: convertLocationInfo(location_info, service.code),
    ocrId: ocr?.id,
    register,
    result: extractResult(originalItem) as any,
    submitData: detailed_service_data ?? {},
    updatedAt: last_status_change_at,
    validUntil: valid_until,
    validUntilDisplay: displayDate(valid_until),
    extractSummary: (getExtractSummaryForTesting(service, extracted_summary) as any) ?? null,
  }

  if (purchase?.recurrence) {
    orderItem.recurrence = {
      id: purchase.recurrence.id,
      name: purchase.recurrence.name,
    }
  }

  return orderItem
}

function getExtractSummaryForTesting(service: ServiceAPI, extracted_summary?: ExtractedSummary | null) {
  if (!LOCAL) return extracted_summary

  if (CertificateCode.CERTIDAO_JUSTICA_ESTADUAL === (service.code as any)) {
    return {
      processos: [
        {
          status: 'positiva',
          vara: 'Setor de Execuções Fiscais',
          foro: 'Foro de Batatais',
          comarca: 'Batatais',
          data_distribuicao: '12/11/2014',
          numero_processo: '0004192-72.2014.8.26.0070',
          tipo_processo: 'Execução Fiscal',
          requerente: 'Prefeitura Municipal de Batatais',
          requerido: 'Rodrigo dos Santos Policeno Bernardes',
        },
        {
          status: 'positiva',
          vara: 'JUIZADO ESPECIAL CÍVEL E CRIMINAL',
          foro: 'Foro de Batatais',
          comarca: 'Batatais',
          data_distribuicao: '29/07/2024',
          numero_processo: '1002479-93.2024.8.26.0070',
          tipo_processo: 'Procedimento do Juizado Especial Cível',
          requerente: 'Caroline Ramos Martins',
          requerido: 'Rodrigo dos Santos Policeno Bernardes',
        },
        {
          status: 'positiva',
          vara: '14 Vara Cível',
          foro: 'Foro de Batatais',
          comarca: 'Batatais',
          data_distribuicao: '09/11/2022',
          numero_processo: '1003648-86.2022.8.26.0070',
          tipo_processo: 'Procedimento Comum Cível',
          requerente: 'Fernanda Cristina Marchi',
          requerido: 'Rodrigo dos Santos Policeno Bernardes',
        },
        {
          status: 'positiva',
          vara: 'Setor de Execuções Fiscais',
          foro: 'Foro de Batatais',
          comarca: 'Batatais',
          data_distribuicao: '12/12/2017',
          numero_processo: '1500315-45.2017.8.26.0070',
          tipo_processo: 'Execução Fiscal',
          requerente: 'Prefeitura Municipal de Batatais',
          requerido: 'Rodrigo dos Santos Policeno Bernardes',
        },
        {
          status: 'positiva',
          vara: 'Setor de Execuções Fiscais',
          foro: 'Foro de Batatais',
          comarca: 'Batatais',
          data_distribuicao: '18/10/2018',
          numero_processo: '1501158-73.2018.8.26.0070',
          tipo_processo: 'Execução Fiscal',
          requerente: 'Prefeitura Municipal de Batatais',
          requerido: 'Rodrigo dos Santos Policeno Bernardes',
        },
        {
          status: 'positiva',
          vara: 'Setor de Execuções Fiscais',
          foro: 'Foro de Batatais',
          comarca: 'Batatais',
          data_distribuicao: '20/12/2022',
          numero_processo: '1501382-69.2022.8.26.0070',
          tipo_processo: 'Execução Fiscal',
          requerente: 'Prefeitura Municipal de Batatais',
          requerido: 'Rodrigo dos Santos Policeno Bernardes',
        },
        {
          status: 'positiva',
          vara: 'Setor de Execuções Fiscais',
          foro: 'Foro de Batatais',
          comarca: 'Batatais',
          data_distribuicao: '18/08/2023',
          numero_processo: '1501774-72.2023.8.26.0070',
          tipo_processo: 'Execução Fiscal',
          requerente: 'Prefeitura Municipal de Batatais',
          requerido: 'Rodrigo dos Santos Policeno Bernardes',
        },
        {
          status: 'positiva',
          vara: 'Setor de Execuções Fiscais',
          foro: 'Foro de Batatais',
          comarca: 'Batatais',
          data_distribuicao: '11/11/2019',
          numero_processo: '1502415-02.2019.8.26.0070',
          tipo_processo: 'Execução Fiscal',
          requerente: 'Prefeitura Municipal de Batatais',
          requerido: 'Rodrigo dos Santos Policeno Bernardes',
        },
        {
          status: 'positiva',
          vara: 'Setor de Execuções Fiscais',
          foro: 'Foro de Batatais',
          comarca: 'Batatais',
          data_distribuicao: '30/08/2023',
          numero_processo: '1502636-43.2023.8.26.0070',
          tipo_processo: 'Execução Fiscal',
          requerente: 'Prefeitura Municipal de Batatais',
          requerido: 'Rodrigo dos Santos Policeno Bernardes',
        },
        {
          status: 'positiva',
          vara: 'Setor de Execuções Fiscais',
          foro: 'Foro de Batatais',
          comarca: 'Batatais',
          data_distribuicao: '26/11/2020',
          numero_processo: '1503769-28.2020.8.26.0070',
          tipo_processo: 'Execução Fiscal',
          requerente: 'Prefeitura Municipal de Batatais',
          requerido: 'Rodrigo dos Santos Policeno Bernardes',
        },
      ],
    }
  }
  if (CertificateCode.CERTIDAO_TRF_DISTRIBUICAO === (service.code as any)) {
    return {
      classes: '-',
      assuntos: '-',
      apelantes: '-',
      nome_reus: ['LUIZ CARLOS DE ARRUDA JUNIOR'],
      situacoes: '-',
      data_emissao: '12/03/2021',
      varas_processos: '-',
      numeros_processos: '-',
      orgaos_julgadores: [
        'TRIBUNAL REGIONAL FEDERAL DA 1ª REGIÃO',
        'TRIBUNAL REGIONAL FEDERAL DA 2ª REGIÃO',
        'TRIBUNAL REGIONAL FEDERAL DA 3ª REGIÃO',
        'TRIBUNAL REGIONAL FEDERAL DA 4ª REGIÃO',
        'TRIBUNAL REGIONAL FEDERAL DA 5ª REGIÃO',
      ],
      datas_distribuicao_processos: '-',
      declaracao_inexistencia_processos: ['negativa'],
    }
  }

  if (
    [CertificateCode.CERTIDAO_IMOVEL, CertificateCode.CERTIDAO_PREVIA_MATRICULA].includes(service.code as any)
  ) {
    return {
      area: {
        total: '1.579,37 m2',
        perimetro: null,
        privativa: null,
        comum: null,
        util: null,
        construida: null,
      },
      proprietarios: [
        {
          nome: 'MARCOS KHERLAKIAN',
          documento: '755.064.328-87',
        },
        {
          nome: 'MICHAELA UTTINGER KHERLAKIAN',
          documento: null,
        },
      ],
      tipo_ativo: 'Urbano',
      subtipo_ativo: 'Terreno',
      endereco:
        'Av. das Granadas, Parque Friburgo - 3a. Seção, quadra 10, parte do lote 4, lote 5 e parte do lote 6',
      cidade: 'Mairiporã',
      unidade_federativa: 'SP',
      valor: 'Cr$ 20.000.000',
      gravames_ativos: [],
      gravames_inativos: [],
      ultima_atualizacao: '24/10/1984',
    }
  }

  if ([CertificateCode.CERTIDAO_CNDT].includes(service.code as any)) {
    return {
      nome_reus: 'SUZANO S.A.',
      data_emissao: '12/08/2024',
      status_certidao: 'positiva',
      processos: [
        {
          situacao: 'Em tramitação',
          classe: 'CUMPRIMENTO DE SENTENÇA',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '018 - Salvador',
          numero: '1067806-92.2022.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '08° - Salvador',
          numero: '1082020-54.2023.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '08° - Salvador',
          numero: '1029969-03.2022.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '08° - Salvador',
          numero: '0035220-24.2019.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '08° - Salvador',
          numero: '1035679-33.2024.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '08° - Salvador',
          numero: '1084417-23.2022.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '08° - Salvador',
          numero: '1001255-96.2023.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '08° - Salvador',
          numero: '1015667-95.2024.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '08° - Salvador',
          numero: '1035677-63.2024.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '18° - Salvador',
          numero: '1029960-41.2022.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '18° - Salvador',
          numero: '1079022-84.2021.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '18° - Salvador',
          numero: '1080650-11.2021.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '18° - Salvador',
          numero: '1015640-49.2023.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '18° - Salvador',
          numero: '0019010-15.2007.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '18° - Salvador',
          numero: '1080024-55.2022.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '18° - Salvador',
          numero: '1029973-40.2022.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '18° - Salvador',
          numero: '1029966-48.2022.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '18° - Salvador',
          numero: '1017939-04.2020.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '19° - Salvador',
          numero: '1010821-11.2019.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '19° - Salvador',
          numero: '1012677-34.2024.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '20° - Salvador',
          numero: '0010550-58.2015.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '20° - Salvador',
          numero: '1082211-02.2023.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '20° - Salvador',
          numero: '1060990-60.2023.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '20° - Salvador',
          numero: '1050327-57.2020.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '20° - Salvador',
          numero: '1016715-94.2021.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '20° - Salvador',
          numero: '1019274-24.2021.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '20° - Salvador',
          numero: '0027682-89.2019.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '20° - Salvador',
          numero: '0031319-24.2014.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
        {
          situacao: 'Em tramitação',
          classe: 'EXECUÇÃO FISCAL',
          assunto: 'Não especificado',
          apelantes: 'Não especificado',
          vara: '20° - Salvador',
          numero: '0001339-42.2008.4.01.3300',
          orgao_julgador: 'BA',
          data_distribuicao: 'Não especificado',
        },
      ],
    }
  }

  return extracted_summary
}

export function convertLocationInfo(locationAPI: LocationInfoAPI | null, serviceCode: ServiceCode) {
  if (!locationAPI) {
    return {}
  }

  const { cities, federative_units, notaries } = locationAPI

  try {
    const { dynamicFields, formFields } = getServiceConfig(serviceCode)
    const allFields = { ...formFields, ...(dynamicFields ?? {}) }

    return {
      url_uf: convertLocationToSelectOption(federative_units, allFields.url_uf?.multiple),
      url_cidade: convertLocationToSelectOption(cities, allFields.url_cidade?.multiple),
      url_cartorio: convertLocationToSelectOption(notaries, allFields.url_cartorio?.multiple),
    }
  } catch {
    console.error(`Serviço ${serviceCode} não configurado.`)
    return {}
  }
}

// Formato usado em Relatórios
export function convertOrderItemSummary(originalItem: OrderItemAPI, isPostPaid: boolean) {
  const {
    ai_service_name,
    backoffice_id,
    id,
    name,
    placed_at,
    purchase_id,
    service: serviceAPI,
    service_category_id,
    status,
    total_cost,
    total_estimated_cost_postpaid_customer,
    user,
  } = originalItem

  const service = convertToSelectedService(serviceAPI, service_category_id) as SelectedService
  const isAI = serviceAPI.type === ServiceType.AI

  if (isAI && ai_service_name) {
    service.name = `IA: ${ai_service_name}`
  }

  if (serviceAPI.short_name && !isAI) {
    service.shortName = serviceAPI.short_name
  }

  const orderItem: OrderItemSummary = {
    createdAt: stringToDate(placed_at)!,
    createdAtDisplay: displayDate(placed_at),
    id,
    name,
    orderId: purchase_id as number,
    orderItemNumber: backoffice_id,
    service,
    status,
    price: isPostPaid ? total_estimated_cost_postpaid_customer ?? 0 : total_cost,
    priceDisplay: formatMoney(isPostPaid ? total_estimated_cost_postpaid_customer ?? 0 : total_cost),
    user: {
      id: user.id,
      name: user.name,
    },
  }

  return orderItem
}

function extractResult(item: OrderItemAPI) {
  const { result, result_details, service, status } = item

  if (!result || status !== StatusCode.FINALIZADO) {
    return null
  }

  // Esta lógica deveria estar no back
  if (
    !result &&
    service.code === ResearchCode.PESQUISA_BENS &&
    result_details?.some(elem => !!elem.matricula)
  ) {
    return ResultCode.POSITIVE
  }

  return result
}

import { CertificateCode, DocumentOrResearchCode, DocumentRegistrationCode, ResearchCode } from '../types'

import CertidaoAmbiental from './certificates/certidaoAmbiental/components/CreateOrderCertidaoAmbiental'
import CertidaoAmbientalMunicipal from './certificates/certidaoAmbientalMunicipal/components/CreateOrderCertidaoAmbientalMunicipal'
import CertidaoAntecedentesCriminais from './certificates/certidaoAntecedentesCriminais/components/CreateOrderCertidaoAntecedentesCriminais'
import CertidaoAtasNotariais from './certificates/certidaoAtasNotariais/components/CreateOrderCertidaoAtasNotariais'
import CreateOrderCertidaoBaixaProtesto from './certificates/certidaoBaixaProtesto/CreateOrderCertidaoBaixaProtesto'
import CreateOrderCertidaoConsultaSituacaoPJ from './certificates/certidaoConsultaSituacaoPJ/components/CreateOrderCertidaoConsultaSituacaoPJ'
import CertidaoDadosCadastraisImovel from './certificates/certidaoDadosCadastraisImovel/components/CreateOrderCertidaoDadosCadastraisImovel'
import CertidaoCafir from './certificates/certidaoCafir/components/CreateOrderCertidaoCafir'
import CertidaoCasamento from './certificates/certidaoCasamento/components/CreateOrderCertidaoCasamento'
import CertidaoCapaIPTU from './certificates/certidaoCapaIPTU/components/CreateOrderCertidaoCapaIPTU'
import CertidaoCCIR from './certificates/certidaoCCIR/components/CreateOrderCertidaoCCIR'
import CertidaoCEAT from './certificates/certidaoCEAT/components/CreateOrderCertidaoCEAT'
import CertidaoCNDT from './certificates/certidaoCNDT/components/CreateOrderCertidaoCNDT'
import CertidaoCNDTNIDA from './certificates/certidaoCNDTNIDA/components/CreateOrderCertidaoCNDTNIDA'
import CertidaoCumprimentoPCDs from './certificates/certidaoCumprimentoPCDs/components/CreateOrderCertidaoCumprimentoPCDs'
import CertidaoDebitosEstaduais from './certificates/certidaoDebitosEstaduais/components/CreateOrderCertidaoDebitosEstaduais'
import CertidaoDebitosMT from './certificates/certidaoDebitosMT/components/CreateOrderCertidaoDebitosMT'
import CertidaoDebitosMunicipais from './certificates/certidaoDebitosMunicipais/components/CreateOrderCertidaoDebitosMunicipais'
import CertidaoDistribuicao from './certificates/certidaoTRFDistribuicao/components/CreateOrderCertidaoTRFDistribuicao'
import CertidaoEscritura from './certificates/certidaoEscritura/components/CreateOrderCertidaoEscritura'
import CertidaoEmpresaCartoriosPJ from './certificates/certidaoEmpresaCartoriosPJ/components/CreateOrderCertidaoEmpresaCartoriosPJ'
import CertidaoEntidadesSupervisionadas from './certificates/certidaoEntidadesSupervisionadas/components/CreateOrderCertidaoEntidadesSupervisionadas'
import CertidaoExtratoDebitosMunicipais from './certificates/certidaoExtratoDebitosMunicipais/components/CreateOrderCertidaoExtratoDebitosMunicipais'
import CertidaoIbamaDebitos from './certificates/certidaoIbamaDebitos/components/CreateOrderCertidaoIbama'
import CertidaoIbamaEmbargos from './certificates/certidaoIbamaEmbargos/components/CreateOrderCertidaoEmbargosIbama'
import CertidaoImovel from './certificates/certidaoImovel/components/CreateOrderCertidaoImovel'
import CertidaoInterdicao from './certificates/certidaoInterdicao/components/CreateOrderCertidaoInterdicao'
import CertidaoIPTU from './certificates/certidaoIPTU/components/CreateOrderCertidaoIPTU'
import CertidaoITR from './certificates/certidaoITR/components/CreateOrderCertidaoITR'
import CertidaoImprobabilidadeAdministrativa from './certificates/certidaoImprobabilidadeAdministrativa/components/CreateOrderImprobabilidadeAdministrativa'
import CertidaoJuntaComercial from './certificates/certidaoJuntaComercial/components/CreateOrderCertidaoJuntaComercial'
import CertidaoJusticaEstadual from './certificates/certidaoJusticaEstadual/components/CreateOrderCertidaoJusticaEstadual'
import CertidaoMPECivil from './certificates/certidaoMPECivil/components/CreateOrderCertidaoMPECivil'
import CertidaoMTTrabalhistas from './certificates/certidaoMTTrabalhistas/components/CreateOrderCertidaoMTTrabalhistas'
import CertidaoMPECriminais from './certificates/certidaoMPECriminal/components/CreateOrderCertidaoMPECriminal'
import CertidaoNascimento from './certificates/certidaoNascimento/components/CreateOrderCertidaoNascimento'
import CertidaoNegativaCorrecional from './certificates/certidaoNegativaCorrecional/components/CreateOrderCertidaoNegativaCorrecional'
import CertidaoNegativaCorrecionalCGU from './certificates/certidaoNegativaCorrecionalCGU/components/CreateOrderCertidaoNegativaCorrecionalCGU'
import CertidaoNegativaDebitosTributariosPGE from './certificates/certidaoNegativaDebitosTributariosPGE/components/CreateOrderCertidaoNegativaDebitosTributariosPGE'
import CertidaoNegativaContasTCU from './certificates/certidaoNegativaContasTCU/components/CreateOrderCertidaoNegativaContasTCU'
import CertidaoNegativaFeitos from './certificates/certidaoNegativaFeitos/components/CreateOrderCertidaoNegativaFeitos'
import CertidaoNegativaFGTS from './certificates/certidaoNegativaFGTS/components/CreateOrderCertidaoNegativaFGTS'
import CertidaoNegativaSTJ from './certificates/certidaoNegativaSTJ/components/CreateOrderCertidaoNegativaSTJ'
import CertidaoNegativaMPF from './certificates/certidaoNegativaMPF/components/CreateOrderCertidaoNegativaMPF'
import CertidaoNegativaTCU from './certificates/certidaoNegativaTCU/components/CreateOrderCertidaoNegativaTCU'
import CertidaoObito from './certificates/certidaoObito/components/CreateOrderCertidaoObito'
import CertidaoPenhorSafra from './certificates/certidaoPenhorSafra/components/CreateOrderCertidaoPenhorSafra'
import CertidaoPreviaMatricula from './certificates/certidaoPreviaMatricula/components/CreateOrderPreviaMatricula'
import CertidaoProcuracao from './certificates/certidaoProcuracao/components/CreateOrderCertidaoProcuracao'
import CertidaoProtesto from './certificates/certidaoProtesto/components/CreateOrderCertidaoProtesto'
import CertidaoPropriedadeAeronave from './certificates/certidaoPropriedadeAeronave/components/CreateOrderCertidaoPropriedadeAeronave'
import CertidaoPronafAptidao from './certificates/certidaoPronafAptidao/components/CreateOrderCertidaoPronafAptidao'
import CertidaoQuitacaoEleitoral from './certificates/certidaoQuitacaoEleitoral/components/CreateOrderCertidaoQuitacaoEleitoral'
import CertidaoValorVenal from './certificates/certidaoValorVenal/components/CreateOrderCertidaoValorVenal'
import CertidaoSTFDistribuidor from './certificates/certidaoSTFDistribuidor/components/CreateOrderCertidaoDistribuidor'
import CertidaoSTMCriminais from './certificates/certidaoSTMCriminais/components/CreateOrderCertidaoSTMCriminais'
import CertidaoTribunalContas from './certificates/certidaoTribunalContas/components/CreateOrderCertidaoTribunalContas'
import CertidaoSPU from './certificates/certidaoSPU/components/CreateOrderCertidaoSPU'
import CertidaoRAIS from './certificates/certidaoRAIS/components/CreateOrderCertidaoRAIS'
import CreateOrderRGI from './documentsRegistration/rgi/components/CreateOrderRGI'
import CreateOrderRTD from './documentsRegistration/rtd/components/CreateOrderRTD'
import CreateOrderAtaNotarial from './documentsRegistration/ataNotarial/components/CreateOrderAtaNotarial'
import PesquisaBens from './researches/pesquisaBens/components/CreateOrderPesquisaBens'
import PesquisaCar from './researches/pesquisaCar/components/CreateOrderPesquisaCar'
import PesquisaDadosCadastrais from './researches/pesquisaDadosCadastrais/components/CreateOrderPesquisaDadosCadastrais'
import PesquisaDividaAtivaPGE from './researches/pesquisaDividaAtivaPGE/components/CreateOrderPesquisaDividaAtivaPGE'
import PesquisaDivorcio from './researches/pesquisaDivorcio/components/CreateOrderPesquisaDivorcio'
import PesquisaEmpresaCartoriosPJ from './researches/pesquisaEmpresaCartoriosPJ/components/CreateOrderPesquisaEmpresaCartoriosPJ'
import PesquisaEscritura from './researches/pesquisaEscritura/components/CreateOrderPesquisaEscritura'
import PesquisaImovelRural from './researches/pesquisaImovelRural/components/CreateOrderPesquisaImovelRural'
import PesquisaInventario from './researches/pesquisaInventario/components/CreateOrderPesquisaInventario'
import PesquisaIndicadoresAtividade from './researches/pesquisaIndicadoresAtividade/components/CreateOrderPesquisaIndicadoresAtividade'
import PesquisaJuntaComercial from './researches/pesquisaJuntaComercial/components/CreateOrderPesquisaJuntaComercial'
import PesquisaObito from './researches/pesquisaObito/components/CreateOrderPesquisaObito'
import PesquisaParticipacaoSocietaria from './researches/pesquisaParticipacaoSocietaria/components/CreateOrderPesquisaParticipacaoSocietaria'
import PesquisaProcessosJudiciais from './researches/pesquisaProcessosJudiciais/components/CreateOrderPesquisaProcessosJudiciais'
import PesquisaProtesto from './researches/pesquisaProtesto/components/CreateOrderPesquisaProtesto'
import PesquisaProcuracao from './researches/pesquisaProcuracao/components/CreateOrderPesquisaProcuracao'
import PesquisaPropriedadeAeronave from './researches/pesquisaPropriedadeAeronave/components/CreateOrderPesquisaPropriedadeAeronave'
import PesquisaReceitaFederal from './researches/pesquisaReceitaFederal/components/CreateOrderPesquisaReceitaFederal'
import PesquisaSintegra from './researches/pesquisaSintegra/components/CreateOrderPesquisaSintegra'
import PesquisaVeiculos from './researches/pesquisaVeicular/components/CreateOrderPesquisaVeicular'
import PesquisaPropriedadeVeiculos from './researches/pesquisaPropriedadeVeiculos/components/CreateOrderPesquisaPropriedadeVeiculos'
import PesquisaCompliance from './researches/pesquisaCompliance/components/CreateOrderPesquisaCompliance'

const productsCreateOrder: Record<DocumentOrResearchCode, any> = {
  [CertificateCode.CERTIDAO_AMBIENTAL]: CertidaoAmbiental,
  [CertificateCode.CERTIDAO_AMBIENTAL_MUNICIPAL]: CertidaoAmbientalMunicipal,
  [CertificateCode.CERTIDAO_ANTECEDENTES_CRIMINAIS]: CertidaoAntecedentesCriminais,
  [CertificateCode.CERTIDAO_ATAS_NOTARIAIS]: CertidaoAtasNotariais,
  [CertificateCode.CERTIDAO_BAIXA_PROTESTO]: CreateOrderCertidaoBaixaProtesto,
  [CertificateCode.CERTIDAO_DADOS_CADASTRAIS_IMOVEL]: CertidaoDadosCadastraisImovel,
  [CertificateCode.CERTIDAO_CAFIR]: CertidaoCafir,
  [CertificateCode.CERTIDAO_CASAMENTO]: CertidaoCasamento,
  [CertificateCode.CERTIDAO_CAPA_IPTU]: CertidaoCapaIPTU,
  [CertificateCode.CERTIDAO_CCIR]: CertidaoCCIR,
  [CertificateCode.CERTIDAO_CEAT]: CertidaoCEAT,
  [CertificateCode.CERTIDAO_CNDT]: CertidaoCNDT,
  [CertificateCode.CERTIDAO_CNDTNIDA]: CertidaoCNDTNIDA,
  [CertificateCode.CERTIDAO_CUMPRIMENTO_PCDS]: CertidaoCumprimentoPCDs,
  [CertificateCode.CERTIDAO_DEBITOS_ESTADUAIS]: CertidaoDebitosEstaduais,
  [CertificateCode.CERTIDAO_DEBITOS_MT]: CertidaoDebitosMT,
  [CertificateCode.CERTIDAO_DEBITOS_MUNICIPAIS]: CertidaoDebitosMunicipais,
  [CertificateCode.CERTIDAO_TRF_DISTRIBUICAO]: CertidaoDistribuicao,
  [CertificateCode.CERTIDAO_DISTRIBUIDOR]: CertidaoSTFDistribuidor,
  [CertificateCode.CERTIDAO_EMPRESA_CARTORIO_PJ]: CertidaoEmpresaCartoriosPJ,
  [CertificateCode.CERTIDAO_ENTIDADES_SUPERFISIONADAS]: CertidaoEntidadesSupervisionadas,
  [CertificateCode.CERTIDAO_ESCRITURA]: CertidaoEscritura,
  [CertificateCode.CERTIDAO_EXTRATO_DEBITOS_MUNICIPAIS]: CertidaoExtratoDebitosMunicipais,
  [CertificateCode.CERTIDAO_IBAMA_DEBITOS]: CertidaoIbamaDebitos,
  [CertificateCode.CERTIDAO_IBAMA_EMBARGOS]: CertidaoIbamaEmbargos,
  [CertificateCode.CERTIDAO_IPTU]: CertidaoIPTU,
  [CertificateCode.CERTIDAO_ITR]: CertidaoITR,
  [CertificateCode.CERTIDAO_IMPROBILIDADE_ADMINISTRATIVA]: CertidaoImprobabilidadeAdministrativa,
  [CertificateCode.CERTIDAO_IMOVEL]: CertidaoImovel,
  [CertificateCode.CERTIDAO_INTERDICAO]: CertidaoInterdicao,
  [CertificateCode.CERTIDAO_JUNTA_COMERCIAL]: CertidaoJuntaComercial,
  [CertificateCode.CERTIDAO_JUSTICA_ESTADUAL]: CertidaoJusticaEstadual,
  [CertificateCode.CERTIDAO_MT_TRABALHISTAS]: CertidaoMTTrabalhistas,
  [CertificateCode.CERTIDAO_MPE_CIVIL]: CertidaoMPECivil,
  [CertificateCode.CERTIDAO_MPE_CRIMINAL]: CertidaoMPECriminais,
  [CertificateCode.CERTIDAO_NASCIMENTO]: CertidaoNascimento,
  [CertificateCode.CERTIDAO_NEGATIVA_CONTAS_TCU]: CertidaoNegativaContasTCU,
  [CertificateCode.CERTIDAO_NEGATIVA_CORRECIONAL]: CertidaoNegativaCorrecional,
  [CertificateCode.CERTIDAO_NEGATIVA_CORRECIONAL_CGU]: CertidaoNegativaCorrecionalCGU,
  [CertificateCode.CERTIDAO_NEGATIVA_DEBITOS_TRIBUTARIOS_PGE]: CertidaoNegativaDebitosTributariosPGE,
  [CertificateCode.CERTIDAO_NEGATIVA_FEITOS]: CertidaoNegativaFeitos,
  [CertificateCode.CERTIDAO_NEGATIVA_FGTS]: CertidaoNegativaFGTS,
  [CertificateCode.CERTIDAO_NEGATIVA_MPF]: CertidaoNegativaMPF,
  [CertificateCode.CERTIDAO_NEGATIVA_STJ]: CertidaoNegativaSTJ,
  [CertificateCode.CERTIDAO_NEGATIVA_TCU]: CertidaoNegativaTCU,
  [CertificateCode.CERTIDAO_OBITO]: CertidaoObito,
  [CertificateCode.CERTIDAO_PENHOR_SAFRA]: CertidaoPenhorSafra,
  [CertificateCode.CERTIDAO_PROCURACAO]: CertidaoProcuracao,
  [CertificateCode.CERTIDAO_PROTESTO]: CertidaoProtesto,
  [CertificateCode.CERTIDAO_PRONAF_APTIDAO]: CertidaoPronafAptidao,
  [CertificateCode.CERTIDAO_QUITACAO_ELEITORAL]: CertidaoQuitacaoEleitoral,
  [CertificateCode.CERTIDAO_STM_CRIMINAIS]: CertidaoSTMCriminais,
  [CertificateCode.CERTIDAO_PREVIA_MATRICULA]: CertidaoPreviaMatricula,
  [CertificateCode.CERTIDAO_PROPRIEDADE_AERONAVE]: CertidaoPropriedadeAeronave,
  [CertificateCode.CERTIDAO_TRIBUNAL_CONTAS]: CertidaoTribunalContas,
  [CertificateCode.CERTIDAO_VALOR_VENAL]: CertidaoValorVenal,
  [CertificateCode.CERTIDAO_SPU]: CertidaoSPU,
  [CertificateCode.CERTIDAO_RAIS]: CertidaoRAIS,
  [CertificateCode.CERTIDAO_SITUACAO_PJ]: CreateOrderCertidaoConsultaSituacaoPJ,
  [DocumentRegistrationCode.RGI]: CreateOrderRGI,
  [DocumentRegistrationCode.RTD]: CreateOrderRTD,
  [DocumentRegistrationCode.ATA_NOTARIAL]: CreateOrderAtaNotarial,
  [ResearchCode.PESQUISA_BENS]: PesquisaBens,
  [ResearchCode.PESQUISA_CAR]: PesquisaCar,
  [ResearchCode.PESQUISA_DADOS_CADASTRAIS]: PesquisaDadosCadastrais,
  [ResearchCode.PESQUISA_DIVIDA_ATIVA_PGE]: PesquisaDividaAtivaPGE,
  [ResearchCode.PESQUISA_DIVORCIO]: PesquisaDivorcio,
  [ResearchCode.PESQUISA_EMPRESA_CARTORIOS_PJ]: PesquisaEmpresaCartoriosPJ,
  [ResearchCode.PESQUISA_ESCRITURA]: PesquisaEscritura,
  [ResearchCode.PESQUISA_IMOVEL_RURAL]: PesquisaImovelRural,
  [ResearchCode.PESQUISA_INVENTARIO]: PesquisaInventario,
  [ResearchCode.PESQUISA_INDICADORES_ATIVIDADE]: PesquisaIndicadoresAtividade,
  [ResearchCode.PESQUISA_JUNTA_COMERCIAL]: PesquisaJuntaComercial,
  [CertificateCode.CERTIDAO_NEGATIVA_PROPRIEDADE]: null,
  [ResearchCode.PESQUISA_OBITO]: PesquisaObito,
  [ResearchCode.PESQUISA_PARTICIPACAO_SOCIETARIA]: PesquisaParticipacaoSocietaria,
  [ResearchCode.PESQUISA_PROCESSOS_JUDICIAIS]: PesquisaProcessosJudiciais,
  [ResearchCode.PESQUISA_PROCURACAO]: PesquisaProcuracao,
  [ResearchCode.PESQUISA_PROPRIEDADE_AERONAVE]: PesquisaPropriedadeAeronave,
  [ResearchCode.PESQUISA_PROTESTO]: PesquisaProtesto,
  [ResearchCode.PESQUISA_RECEITA_FEDERAL]: PesquisaReceitaFederal,
  [ResearchCode.PESQUISA_SINTEGRA]: PesquisaSintegra,
  [ResearchCode.PESQUISA_VEICULAR]: PesquisaVeiculos,
  [ResearchCode.PESQUISA_PROPRIEDADE_VEICULOS]: PesquisaPropriedadeVeiculos,
  [ResearchCode.PESQUISA_COMPLIANCE]: PesquisaCompliance,
}

export default productsCreateOrder

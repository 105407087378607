import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Descriptions, DescriptionsProps, Table, TableColumnsType } from 'antd'
import { TableRowSelection } from 'antd/es/table/interface'

import { handleRequestError } from 'api/HandleRequestError'
import ResearchResultOrder from 'domain/createOrder/researchResults/classes/ResearchResultOrder'
import { useAddToCart } from 'domain/createOrder/shared'
import { useIsCustomerPostPaid } from 'domain/customers'
import { ViewCertificateTabs } from 'domain/orderItem/certificate'
import ViewCertificateActions from 'domain/orderItem/certificate/components/ViewCertificateActions'
import ViewCertificateStatus from 'domain/orderItem/certificate/components/ViewCertificateStatus'
import {  useSetActiveTab } from 'domain/orderItem/certificate/state'
import { useGetOrderItem, ViewOrderLayout } from 'domain/orderItem/shared'
import {
  ExtractedSummary,
  ExtractedSummaryProcesses,
  ExtractedSummaryProcessLabels,
} from 'domain/orderItem/typesAPI'
import { Button } from 'ui'
import { DISPLAY_DATE_FORMAT, isAfter, parseDate, validateDate } from 'utils/dateTime'
import { singularOrPlural } from 'utils/formatters'
import { OpenAIEnrichment } from 'domain/openAI/enrich'

export default function ViewCertidaoJusticaEstadual() {
  const { fileURL, ocr, extractSummary, service } = useGetOrderItem()
  const setActiveTab = useSetActiveTab()

  return (
    <ViewOrderLayout downloadButton={<ViewCertificateActions />}>
      <ViewCertificateStatus>
        <ViewCertificateTabs
          fileUrl={fileURL!}
          ocr={ocr!}
          onChange={setActiveTab}
          reportTab={extractSummary && <ViewReport summary={extractSummary} />}
          openAITab={<OpenAIEnrichment fileUrl={fileURL!} ocr={ocr!} serviceId={service.id} />}
        />
      </ViewCertificateStatus>
    </ViewOrderLayout>
  )
}

interface ViewReportProps {
  summary: ExtractedSummary
}

function ViewReport({ summary }: ViewReportProps) {
  const isPostPaid = useIsCustomerPostPaid()
  const orderItem = useGetOrderItem()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const addToCart = useAddToCart()
  const history = useHistory()

  const { columns, dataSource } = useMemo(() => {
    const columns: TableColumnsType<
      Pick<ExtractedSummaryProcesses, 'numero_processo' | 'data_distribuicao' | 'comarca'> & {
        expandable: DescriptionsProps['items']
      }
    > = [
      {
        key: 'numero_processo',
        title: 'Número do processo',
        dataIndex: 'numero_processo',
        sorter: (a, b) => {
          if (a.numero_processo && b.numero_processo) {
            return a.numero_processo.length - b.numero_processo.length
          }
          return 0
        },
      },
      {
        title: 'Data distribuicao',
        dataIndex: 'data_distribuicao',
        key: 'data_distribuicao',
        sorter: (a, b) => {
          if (validateDate(a.data_distribuicao!) && validateDate(b.data_distribuicao!)) {
            if (
              isAfter(
                parseDate(a.data_distribuicao!, DISPLAY_DATE_FORMAT).toDate(),
                parseDate(b.data_distribuicao!, DISPLAY_DATE_FORMAT).toDate()
              )
            ) {
              return 1
            } else {
              return -1
            }
          }
          return 0
        },
      },
      {
        title: 'Comarca',
        dataIndex: 'comarca',
        key: 'comarca',
      },
      {
        title: '',
        key: 'actions',
      },
    ]

    const dataSource = summary.processos?.map(
      ({ numero_processo, data_distribuicao, comarca, ...rest }, index) => ({
        key: index,
        numero_processo,
        data_distribuicao,
        comarca,
        expandable: Object.entries(rest)
          .filter(([key]) => ExtractedSummaryProcessLabels[key])
          .map(([key, value]) => ({
            key,
            label: ExtractedSummaryProcessLabels[key],
            children: <p>{value}</p>,
          })),
      })
    )
    return {
      columns,
      dataSource,
    }
  }, [summary])
  const statusNegative = summary.processos?.map(p => p.status).includes('negativa')

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const handleOrder = async () => {
    setLoading(true)
    const result = dataSource?.filter(d => selectedRowKeys.includes(d.key)) 

    if (!result) return null
    const errors = []
    for (const r of result) {
      try {
        const createOrder = await new ResearchResultOrder(orderItem, r, 'extractReport').generate(isPostPaid!)
        addToCart(createOrder) 
      } catch (err) {
        errors.push(err)
      }
    }

    setLoading(false)
    if (errors.length > 0) {
      handleRequestError(errors)
    } else {
      history.push('/carrinho')
    }
  }

  return (
    <div className="mx-6">
      <section className="mt-8">
        <h2 className="text-secondary-500 font-bold text-lg">Dados da certidão</h2>
        <div className="flex gap-4 my-6">
          <p className="basis-1/4">Status da certidão</p>
          <div className={`flex gap-2 items-center text-${statusNegative ? 'blue-500' : 'error'} capitalize`}>
            <div className={`rounded-full w-3 h-3 bg-${statusNegative ? 'blue-500' : 'error'}`}></div>
            {statusNegative ? 'Negativa' : 'Positiva'}
          </div>
        </div>
        <div className="flex gap-4 my-6">
          <p className="basis-1/4">Data da emissão</p>
          <div>{summary.emissao ? summary.emissao : '-'}</div>
        </div>
      </section>
      <section>
        <h2 className="text-secondary-500 font-bold text-lg mb-4">Processos</h2>
        <div className="text-base">
          Veja abaixo os processos relacionados a certidão, é possível fazer uma pesquisa dos processos e
          acessar informações detalhadas e atualizadas de todos os tribunais do Brasil.
        </div>
        <div className="my-6 flex items-center justify-start gap-6">
          <Button 
            type="primary" 
            size="middle" 
            className="text-base flex items-center justify-center pb-1" 
            disabled={loading || selectedRowKeys.length === 0} 
            onClick={handleOrder}
            loading={loading}
          >
            Solicitar Pesquisa de Processos
          </Button>
          <p className="text-sm font-semibold">
            {selectedRowKeys.length > 0
              ? `${singularOrPlural(selectedRowKeys.length, 'Item selecionado', 'Itens selecionados')}`
              : ''}
          </p>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          expandable={{
            expandedRowRender: ({ expandable }) => (
              <Descriptions
                items={expandable}
                labelStyle={{ color: '#4C566F', fontWeight: 700, fontSize: 14 }}
              />
            ),
            fixed: 'right',
            expandIconColumnIndex: 4,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <Button
                  style={{ float: 'right' }}
                  onClick={e => onExpand(record, e)}
                  icon="chevron-up"
                  type="link"
                >
                  Ocultar
                </Button>
              ) : (
                <Button
                  style={{ float: 'right' }}
                  onClick={e => onExpand(record, e)}
                  icon="chevron-down"
                  type="link"
                >
                  Ver mais
                </Button>
              ),
          }}
          dataSource={dataSource}
        />
      </section>
    </div>
  )
}

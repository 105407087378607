import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Checkbox, Form, FormInstance, message } from 'antd'

import { errorHandler } from 'api/errors'
import { HelpPopover } from 'domain/app'
import { ItemsCollapse, SimilarOrdersError } from 'domain/createOrder/shared'
import {
  useAddCreateOrderStateToCart,
  useCheckSimilarOrderMultipleItems,
} from 'domain/createOrder/document/services'
import { useCreateOrderNextStep, useReplicateCommonData } from 'domain/createOrder/document/helpers'
import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document/state'
import { ControlButton, Heading, SubmitButton } from 'ui'

import AddFormItemButton from './CreateOrderAddFormItemButton'
import OrderExistsModal from './multipleItems/MultipleItemsOrderExistsModal'

interface Props {
  formInstance?: FormInstance
  sectionTitle: string
  header?: React.ReactNode
  initialValues: Record<any, any>
  footer?: React.ReactNode
  renderFormItem: (itemId: number) => JSX.Element
  isResearch?: boolean
  disableMultiItems?: boolean
  noRenderOrderName?: boolean
  noReplicateData?: boolean
}

export default function CreateOrderForm({
  sectionTitle,
  header,
  initialValues,
  footer,
  formInstance,
  renderFormItem,
  isResearch,
  disableMultiItems,
  noRenderOrderName,
  noReplicateData,
}: Props) {
  const form = formInstance ?? Form.useForm()[0]
  const dispatch = useDispatch()
  const { enabled: replicateCommonData, toggleEnabled: toggleReplicateCommonData } = useReplicateCommonData()
  const addToCart = useAddCreateOrderStateToCart()
  const [goToNextStep, nextStep] = useCreateOrderNextStep('dados')
  const {
    files,
    formData,
    formItems,
    multipleItemsLoadingPrice,
    selectedService,
    selectedEntityType,
    selectedInstance,
    selectedModel,
  } = useCreateOrderState()
  const items = Object.values(formItems)
  const checkSimilarOrders = useCheckSimilarOrderMultipleItems()
  const [activeItem, setActiveItem] = useState<number | undefined>(
    items.length > 0 ? items[items.length - 1]?.id ?? 0 : 0
  )
  const [similarOrders, setSimilarOrders] = useState<any>([])
  const [status, setStatus] = useState<'checking-similar' | 'has-similar' | 'idle' | 'ok'>('idle')
  const isSubmitting = status === 'checking-similar' || status === 'ok'
  const [itemsQuantity, setItemsQuantity] = useState(1)
  const isUploading = files?.some(f => f.status === 'uploading')
  const hasUploadError = files?.some(f => f.status === 'error')

  const handleSubmit = async (data: any) => {
    try {
      await form.validateFields()
      // Quando o formulário é alterado via setFieldValue, onChangeFields não é disparado
      // Por isso precisamos atualizar formData
      dispatch(createOrderActions.setFormData({ ...formData, ...data }))
      setStatus('checking-similar')
    } catch {
      message.error('Verifique o preenchimento do formulário.')
    }
  }

  // Salva no localStorage
  const onValuesChange = (changedFields: any, allValues: any) => {
    // Não atualiza formData se o campo alterado for o nome do pedido,
    // senão o nome automático sobrescreve o input do usuário
    if (activeItem !== undefined && changedFields[activeItem]?.name === undefined) {
      dispatch(createOrderActions.setFormData({ ...formData, ...allValues }))
    }
  }

  useEffect(() => {
    const handleStatusChange = async () => {
      try {
        if (status === 'checking-similar') {
          try {
            await checkSimilarOrders()
            setStatus('ok')
          } catch (error) {
            if (error instanceof SimilarOrdersError) {
              setSimilarOrders(error.data)
              setStatus('has-similar')
            } else {
              throw error
            }
          }
        } else if (status === 'ok') {
          try {
            if (nextStep === 'cart') {
              addToCart()
            }

            goToNextStep()
          } catch {
            message.error('Verifique o preenchimento do formulário.')
            setStatus('idle')
          }
        }
      } catch (error) {
        setStatus('idle')
        errorHandler(error)
      }
    }

    handleStatusChange()
  }, [status])

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      scrollToFirstError
      form={form}
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
      onFinishFailed={({errorFields}) => {
        setActiveItem(Number(errorFields[0].name[0]))
        message.error('Verifique o preenchimento do formulário.')}
      }
    >
      <OrderExistsModal
        open={status === 'has-similar'}
        totalItems={items.length}
        similarOrders={similarOrders}
        setSimilarOrders={setSimilarOrders}
        onContinue={() => setStatus('ok')}
        onClose={() => setStatus('idle')}
      />

      <Heading size="3" text={sectionTitle} />

      {header}

      {!noReplicateData && (
        <Checkbox className="mb-6" checked={replicateCommonData} onChange={toggleReplicateCommonData}>
          <div className="flex items-center gap-2">
            Replicar dados do titular para todos os documentos
            <HelpPopover
              content={
                <div className="max-w-sm">
                  Essa função irá auto preencher os campos que se repetem no pedido,
                  referente a Pessoa Física e Jurídica (ex: Nome, CPF, Razão Social e CNPJ).
                </div>
              }
              className="mb-1"
            />
          </div>
        </Checkbox>
      )}

      <ItemsCollapse
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        items={items}
        service={selectedService}
        renderFormItem={renderFormItem}
        noRenderOrderName={noRenderOrderName}
      />
      {!disableMultiItems && (
        <div className="flex gap-1 items-center">
          <AddFormItemButton
            label={`Adicionar outra ${isResearch ? 'pesquisa' : 'certidão'}`}
            setActiveItem={setActiveItem}
            disabled={isSubmitting}
            quantity={itemsQuantity}
            extraData={{
              selectedEntityType,
              selectedInstance,
              selectedModel,
            }}
          />
          <ControlButton value={itemsQuantity} onValueChange={setItemsQuantity} />
        </div>
      )}

      {footer}

      <SubmitButton
        marginTop="mt-3"
        loading={isSubmitting || multipleItemsLoadingPrice === true || isUploading}
        disabled={hasUploadError}
      />

      <p className={`text-sm text-center ${hasUploadError ? 'text-error' : 'text-gray-400'}`}>
        {hasUploadError ? 'Houve um erro ao fazer o upload' : isUploading ? 'Upload em andamento...' : ''}
      </p>
    </Form>
  )
}

import React, { useMemo } from 'react'
import { Form, FormInstance } from 'antd'
import { useDispatch } from 'react-redux'

import { getService, getServiceConfig } from 'domain/servicesCBRdoc'
import {
  CertificateCode,
  DocumentRegistrationCode,
  ServiceCode,
  ServiceType,
} from 'domain/servicesCBRdoc/types'
import { Section } from 'layouts'
import { Heading, SubmitButton } from 'ui'

import { useCreateOrderNextStep } from '../helpers'
import { createOrderActions, useCreateOrderState } from '../state'
import SelectLocationFields from './SelectLocationFields'
import { LocationTextInput } from 'domain/servicesCBRdoc/fields'

import LocationInput from 'domain/createOrder/dueDiligence/components/LocationInput'

interface Props {
  autoFocus?: boolean
  sectionTitle?: string
  formInstance?: FormInstance
  children?: React.ReactNode
  header?: React.ReactNode
}

export default function SelectLocationStep({
  formInstance,
  header,
  children,
  sectionTitle,
  autoFocus = true,
}: Props) {
  const form = formInstance ?? Form.useForm()[0]
  const [goToNextStep] = useCreateOrderNextStep('localizacao')
  const { selectedLocation, selectedService, selectedType, selectedModel } = useCreateOrderState()
  const { formFields } = getService(selectedService.code!)
  const showLocationServiceField =
    !!formFields.local_servico &&
    formFields.local_servico.showIf &&
    formFields.local_servico.showIf({ tipo_servico: selectedModel })
  const isAtaNotarial = [
    CertificateCode.CERTIDAO_ATAS_NOTARIAIS,
    DocumentRegistrationCode.ATA_NOTARIAL,
  ].includes(selectedService.code! as any)

  return (
    <Section heading={sectionTitle ?? `Localização ${description[selectedService.type!]}`}>
      <Form
        form={form}
        layout="vertical"
        onFinish={goToNextStep}
        requiredMark={false}
        className="pb-24"
        initialValues={{ ...selectedLocation, tipo: selectedType }}
      >
        {header}

        {showLocationServiceField && (
          <>
            <Heading
              size="3"
              text="Onde o tabelião deve ir para registrar os fatos?"
              description="Informe o local ou endereço na qual deseja que seja feita a Ata Notarial."
            />
            <LocationInput required={showLocationServiceField} />
          </>
        )}

        {isAtaNotarial && (
          <Heading
            size="3"
            text="Especifique em qual cartório a ata será feita"
            description="É necessário informar o Estado e Cidade das partes para que seja lavrada a Ata Notarial em um Cartório de Notas de mesma localidade."
            className="mt-8"
          />
        )}

        <SelectLocation form={form} autoFocus={autoFocus} />

        {children}

        <SubmitButton />
      </Form>
    </Section>
  )
}

function SelectLocation({ form, autoFocus }: { form: FormInstance; autoFocus: boolean }) {
  const { selectedService, selectedModel } = useCreateOrderState()
  const service = getServiceConfig(selectedService.code!)
  const dispatch = useDispatch()

  const onChangeFederativeUnit = () => {
    if (service.LocationUFType) {
      dispatch(createOrderActions.setSelectedType(undefined))
      form.setFieldValue('tipo', undefined)
    }
  }

  const requiredFields = useMemo(() => {
    const fields = {
      required: true,
      requiredUrlUf: true,
      requiredUrlCidade: true,
      requiredUrlCartorio: true,
    }

    if (selectedService.code && selectedService.code === DocumentRegistrationCode.ATA_NOTARIAL) {
      if (selectedModel === 'ja-possuo-as-evidencias') {
        return {
          required: false,
          requiredUrlUf: false,
          requiredUrlCidade: false,
          requiredUrlCartorio: false,
        }
      }
    }

    return fields
  }, [selectedService, selectedModel])

  return (
    <SelectLocationFields
      onChangeFederativeUnit={onChangeFederativeUnit}
      autoFocus={autoFocus}
      {...requiredFields}
    />
  )
}

const description: Record<ServiceType, string> = {
  [ServiceType.AI]: '',
  [ServiceType.CERTIFICATE]: 'do cartório da certidão',
  [ServiceType.DILIGENCE]: 'da diligência',
  [ServiceType.DOCUMENT_REGISTRATION]: 'do registro de documentos',
  [ServiceType.RESEARCH]: 'da pesquisa',
}

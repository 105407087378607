import React, { useEffect, useState } from 'react'
import { Form, FormInstance, UploadFile } from 'antd'

import { AuthorizedAPI } from 'api/network/v1'
import { errorHandler } from 'api/errors'
import { RoutePaths } from 'app/routes'
import { AutoPurchaseResultsCheckbox } from 'domain/createOrder/researches'
import { SimilarOrder, SpreadsheetError, SpreadsheetValidateData } from 'domain/createOrder/types'
import { useCurrentCustomer } from 'domain/customers'
import { CanAutoPurchaseResearchOption } from 'domain/customers/types'
import { ResultCode } from 'domain/orders/types'
import { CartOrderDetailsForm } from 'domain/shoppingCart'
import { displayDateAndTime } from 'utils/dateTime'

import ConfirmPurchase from './SpreadsheetConfirmPurchase'
import SpreadsheetDragger from './SpreadsheetDragger'
import ReviewOrder from './SpreadsheetReviewOrder'
import { SimilarOrdersError } from 'domain/createOrder/shared'

interface Props {
  form: FormInstance
  formData: FormData
  loading: boolean
  serviceId?: number
  categoryId?: number
  kitId?: number
  onSubmit: () => void
  setValidateData: React.Dispatch<React.SetStateAction<SpreadsheetValidateData>>
  countItems: number | undefined
  totalCost: number | undefined
  canAutoOrderPositiveResults?: boolean
  canAutoOrderNegativeResults?: boolean
}

function SpreadsheetForm({
  form,
  formData,
  loading,
  countItems,
  totalCost,
  serviceId,
  categoryId,
  kitId,
  setValidateData,
  onSubmit,
  canAutoOrderNegativeResults,
  canAutoOrderPositiveResults,
}: Props) {
  const [fileList, setFileList] = useState<UploadFile<any>[]>([])
  const [error, setError] = useState<SpreadsheetError>()
  const [warning, setWarning] = useState<string>()
  const customer = useCurrentCustomer()
  const orderNameValue = Form.useWatch('orderName', form)
  const [isValidating, setIsValidating] = useState(false)

  const clearFiles = () => {
    setFileList([])
    setWarning(undefined)
  }

  const cancelOrder = () => {
    setWarning(undefined)
    setValidateData({})
    setError(undefined)
    clearFiles()
  }

  const handleRemove = () => {
    clearFiles()
    if (formData.has('name')) {
      formData.delete('name')
    }

    if (formData.has('spreadsheet')) {
      formData.delete('spreadsheet')
    }
  }

  const missingNameError = () => {
    setError({
      validate: ['Nome do pedido.'],
      message: ['Atenção! Digite um nome para continuar o pedido.'],
    })
  }

  const uploadFiles = async ({ file, onSuccess, onError, config }: any) => {
    try {
      if (kitId && !formData.has('kit_id')) {
        formData.append('kit_id', kitId as any)
      } else {
        if (!formData.has('service_id')) formData.append('service_id', serviceId as any)
        if (!formData.has('service_category_id')) formData.append('service_category_id', categoryId as any)
      }

      const orderName = form.getFieldValue('orderName')
      if (!orderName) {
        missingNameError()
        setError({
          validate: ['Não é possível completar o pedido sem um nome.'],
          message: ['Atenção! Não é possível completar o pedido sem um nome.'],
        })
        onError?.(new Error('Missing orderName data'))
        return
      }

      formData.append('name', form.getFieldValue('orderName'))
      formData.append('spreadsheet', file)

      setIsValidating(true)
      const { data } = await AuthorizedAPI.post(RoutePaths.CREATE_ORDER_SHEET_VALIDATE, formData, config)

      if (!data?.valid) {
        throw new Error('Algo deu errado! Verifique os dados da planilha.', { cause: data })
      }

      if (data.similar_orders && data.similar_orders.length) {
        const similarOrders = data.similar_orders.map(({ order, row }: any) => {
          const similarOrder: SimilarOrder = {
            id: order.id,
            orderName: order.name,
            rowId: row,
          }
          return similarOrder
        })

        setWarning(
          `Você já possui pedidos com os mesmos dados nas linhas ${similarOrders
            .map((s: SimilarOrder) => s.rowId)
            .join(',')}. Remova as linhas ou continue para finalizar a compra com os pedidos repetidos.`
        )
      }

      setValidateData({
        totalOrders: data.number_of_orders,
        totalOrderItems: data.number_of_purchases,
        totalCost: data.total_cost,
        needsAddress: data.needs_address,
        lackingCreditsAmount: data.credits_value_missing,
      })

      setError(undefined)
      onSuccess?.('ok')
    } catch (err: any) {
      if (err?.cause?.errors) {
        setError({
          validate: err.cause.errors,
          message: err.cause.errors,
        })
      } else {
        const statusCode = err.response?.status
        const message = err.response?.data?.message

        if (statusCode && message && [403, 422].includes(statusCode)) {
          setError({
            validate: ['Verifique se está utilizando o template correto da planilha'],
            message: Array.isArray(message) ? message : [message],
          })
        } else {
          errorHandler(err, { code: '0x736' })
        }
      }

      onError?.(err)
    } finally {
      setIsValidating(false)
    }
  }

  const customRequest = async ({ onSuccess, onError, file, onProgress }: any) => {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event: any) => {
        onProgress?.({ percent: (event.loaded / event.total) * 100 })
      },
    }

    setError(undefined)
    setValidateData({})

    await uploadFiles({ file, onSuccess, onError, config })
  }

  const isFileValidated = countItems && countItems > 0

  const hasAutoPurchasePermission =
    customer.permissions.autoPurchaseFromResearch !== CanAutoPurchaseResearchOption.NO

  const initialValues: any = {
    orderName: `Compra por planilha em ${displayDateAndTime(new Date())}`,
  }

  if (hasAutoPurchasePermission) {
    if (canAutoOrderPositiveResults) {
      initialValues.autoOrderPositiveResults =
        customer.permissions.autoPurchaseFromResearch === CanAutoPurchaseResearchOption.YES_DEFAULT
    }

    if (canAutoOrderNegativeResults) {
      initialValues.autoOrderNegativeResults =
        customer.permissions.autoPurchaseFromResearch === CanAutoPurchaseResearchOption.YES_DEFAULT
    }
  }

  useEffect(() => {
    if (!orderNameValue && !kitId) {
      missingNameError()
    } else {
      setError(undefined)
    }
  }, [orderNameValue])
  const disableDragger = isValidating || (!orderNameValue && !kitId)

  return (
    <CartOrderDetailsForm
      form={form}
      initialValues={initialValues}
      onFinish={() => {
        clearFiles()
        onSubmit()
      }}
      hideOrderName={!!kitId}
      isValidating={isValidating}
    >
      {canAutoOrderNegativeResults && hasAutoPurchasePermission && (
        <AutoPurchaseResultsCheckbox name="autoOrderNegativeResults" resultsCode={ResultCode.NEGATIVE} />
      )}

      {canAutoOrderPositiveResults && hasAutoPurchasePermission && (
        <AutoPurchaseResultsCheckbox name="autoOrderPositiveResults" resultsCode={ResultCode.POSITIVE} />
      )}

      <div className="max-w-lg">
        <div className="ant-upload-wrapper p-4 bg-gray-200 bg-opacity-25 border border-gray-200">
          <SpreadsheetDragger
            fileList={fileList}
            onChange={(info: any) => setFileList(info.fileList.slice(-1))}
            customRequest={customRequest}
            error={error}
            onRemove={handleRemove}
            disabled={disableDragger}
            warning={warning}
          />
        </div>

        {isFileValidated && <ReviewOrder countItems={countItems} totalCost={totalCost!} />}
      </div>

      {isFileValidated && <ConfirmPurchase onCancel={cancelOrder} loading={loading} disabled={!!error} />}
    </CartOrderDetailsForm>
  )
}

export default SpreadsheetForm

import {
  AICreateOrderModel,
  AICreateOrderQuestion,
  AIDefaultModel,
  AIDefaultQuestion,
  AIModel,
  AIModelForm,
  AIQuestion,
} from 'domain/openAI/types'
import {
  AICreateOrderModelAPI,
  AICreateOrderModelQuestionAPI,
  AIDefaultQuestionsAPI,
  AIModelAPI,
  AIModelQuestionPostAPI,
  AIQuestionAPI,
} from 'domain/openAI/typesAPI'
import { IconName } from 'ui'

export function convertCreateOrderQuestion(question: AICreateOrderModelQuestionAPI): AICreateOrderQuestion {
  return {
    id: question.id,
    questionToShow: question.label_show_user,
    questionToAi: question.question_to_send_to_ai,
  }
}

export function convertCreateOrderModel(model: AICreateOrderModelAPI) {
  const questions = model.questions?.map(convertCreateOrderQuestion) ?? []

  const data: AICreateOrderModel = {
    id: model.id,
    modelName: model.name,
    documentName: model.document_name,
    fixedSchema: model.fixed_schema,
    questions,
  }

  if (model.service) {
    data.service = { label: model.service.name, value: model.service.id }
    data.icon = model.service.category1?.code as IconName
  }

  return data
}

export function convertModelToUpsertAPI(model: AIModelForm) {
  const modelAPI: AIModelQuestionPostAPI = {
    name: model.modelName,
    questions: model.questions.map(o => ({
      label_show_user: o.questionToShow,
      question_to_send_to_ai: o.questionToAi,
    })),
  }

  if (model.service?.value && model.service.value !== 'other') {
    modelAPI.service_id = model.service.value
  }

  if (model.documentName) {
    modelAPI.document_name = model.documentName
  }

  return modelAPI
}

export function convertDefaultModel(
  model: { id: number },
  questions: AIDefaultQuestionsAPI[] | null | undefined
): AIDefaultModel {
  return {
    id: model.id,
    questions: questions?.map(convertDefaultQuestion) ?? []
  }
}

function convertDefaultQuestion(
  question: AIDefaultQuestionsAPI
): AIDefaultQuestion {
  return {
    id: question.id,
    code: question.code,
    serviceId: question.service_id,
    questionToShow: question.label_show_user,
    answerPlaceholder: question.description_show_user,
  }
}

export function convertAIQuestion(question: AIQuestionAPI): AIQuestion {
  return {
    questionToShow: question.label_show_user,
    questionToAi: question.question,
    answer: question.formatted_response,
  }
}

export function convertAIModel(model: AIModelAPI) {
  const questions = model.questions?.map(convertAIQuestion) ?? []
  const hasAnswers = questions.some(o => o.answer)

  const data: AIModel = {
    id: model.id,
    modelName: model.name,
    questions,
    hasAnswers,
  }

  return data
}

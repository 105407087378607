import React, { useEffect, useMemo, useState } from 'react'
import { Button, Form, UploadFile } from 'antd'
import { useDispatch } from 'react-redux'

import { AuthorizedAPI } from 'api/network/v2'
import { MAX_FILES_QUANTITY, MAX_FILE_SIZE } from 'app/config/files'
import { RoutePaths } from 'app/routes'
import { TempFile, UploadFormData } from 'domain/app/types'
import { Section } from 'layouts'
import { UploadDragger } from 'ui'

import { createOrderActions } from '../state'

interface Props {
  children?: React.ReactNode
  name: string
  defaultFileList?: UploadFile[]
  buttonLabel: string
  header?: React.ReactNode
  headingTitle?: string
  isFinalStep: boolean
  maxCount?: number
  onFinish: (formData: UploadFormData) => void
  onChange?: (files: TempFile[]) => void
}

export default function UploadSection({
  buttonLabel,
  children,
  defaultFileList,
  header,
  headingTitle = 'Enviar arquivos',
  isFinalStep,
  onChange,
  onFinish,
  maxCount = MAX_FILES_QUANTITY,
  ...props
}: Props) {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState<TempFile[]>(defaultFileList ?? [])
  const filesIds = useMemo(() => fileList.map(({ fileUploadId }) => fileUploadId), [fileList])  

  const status = useMemo(() => {
    if (fileList.length === 0) {
      return 'idle'
    }

    if (fileList.some(f => f.status === 'error')) {
      return 'error'
    }

    if (fileList.some(f => f.status === 'uploading')) {
      return 'uploading'
    }

    return 'success'
  }, [fileList])

  const customRequest = async ({ file, onError, onProgress, onSuccess }: any) => {
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (event: any) => {
          onProgress({ percent: (event.loaded / event.total) * 100 })
        },
      }
  
      const form = new FormData()
      form.append('files[]', file)
  
      const { data } = await AuthorizedAPI.post<string[]>(RoutePaths.UPLOAD, form, config)
      const uploadId = data[0]
  
      if (uploadId) {
        const index = fileList.findIndex(o => o.uid === file.uid)
  
        if (index > -1) {
          file.fileUploadId = uploadId
          onSuccess(file)
        }
      }
    } catch (error) {
      onError(error)
    }
  }

  // Para evitar erro de non-serializable no Redux
  const extractFileListData = (fList: TempFile[]): TempFile[] => {
    return fList.map(({ fileUploadId, name, percent, status, thumbUrl, uid, url }) => ({
      fileUploadId,
      name,
      percent,
      status,
      thumbUrl,
      uid,
      url,
    }))
  }

  const handleFinish = (formData: any) => {
    onFinish({ ...formData, files: extractFileListData(fileList) })
  }

  const handleChange = (files: TempFile[]) => {
    if (onChange) {
      onChange(extractFileListData(files))
    }
  }

  useUpdateQuantityListener(fileList.length)

  return (
    <Section heading={headingTitle}>
      <div className="text-sm text-secondary-300">
        {maxCount > 1 ? (
          <p className="mb-4">
            Envie até {maxCount} arquivos em formato PDF. Cada arquivo deve ter, no máximo, {MAX_FILE_SIZE}MB
            de tamanho.
          </p>
        ) : (
          <p className="mb-4">O arquivo deve ter, no máximo, {MAX_FILE_SIZE}MB de tamanho.</p>
        )}

        {header}
      </div>

      <Form form={form} onFinish={handleFinish} layout="vertical" requiredMark={false} className="max-w-lg">
        <UploadDragger
          fileList={fileList}
          setFileList={setFileList}
          accept=".pdf"
          customRequest={customRequest}
          maxFileFize={MAX_FILE_SIZE}
          maxCount={maxCount}
          className="mb-5"
          onChange={handleChange}
          defaultFileList={defaultFileList}
          {...props}
        />

        {children}

        <Button
          htmlType="submit"
          type="primary"
          disabled={
            filesIds.length === 0 ||
            filesIds.length > maxCount ||
            filesIds.some(id => id === undefined || id === null) ||
            fileList.some(f => f.status === 'error') ||
            fileList.some(f => f.status === 'uploading') ||
            (status === 'uploading' && isFinalStep) ||
            status !== 'success'
          }
          loading={status === 'uploading' || filesIds.some(id => id === undefined || id === null)}
          block
        >
          {status === 'uploading' ? 'Enviando arquivos' : buttonLabel}
        </Button>
      </Form>
    </Section>
  )
}

function useUpdateQuantityListener(filesLength: number) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(createOrderActions.setCountItems(filesLength))
  }, [filesLength])
}

import React from 'react'
import { Button as AntdButton, ButtonProps as AntButtonProps } from 'antd'
import { ButtonType as AntButtonType } from 'antd/es/button'

import Icon, { IconName } from 'ui/Icon/Icon'

export type ButtonType = AntButtonType | 'danger' | 'success'

export interface ButtonProps extends AntButtonProps {
  icon?: IconName
  endIcon?: IconName
  iconOnly?: boolean
  iconSize?: number
  unstyled?: boolean
  iconColor?: string
  iconStroke?: number
  iconClassName?: string
}

function Button(
  {
    type = 'primary',
    icon,
    unstyled = false,
    endIcon,
    iconOnly = false,
    children,
    iconSize = 20,
    iconStroke = 1,
    iconColor,
    iconClassName,
    className,
    ...props
  }: ButtonProps,
  ref: any
) {
  return (
    <AntdButton
      type={type}
      ref={ref}
      className={
        unstyled 
          ? className 
          : icon && !iconOnly
            ? `flex flex-row items-center justify-center gap-2 hover:bg-blue-100 ${className}`
            : `${className} hover:bg-blue-100`
      }
      {...props}
    >
      {icon && (
        <Icon
          name={icon}
          className={iconClassName}
          strokeWidth={iconStroke}
          size={iconSize}
          color={iconColor}
        />
      )}
      <div className={icon && !iconOnly ? 'mt-1' : ''}>{children}</div>
      {endIcon && (
        <Icon
          name={endIcon}
          className={iconClassName}
          strokeWidth={iconStroke}
          size={iconSize}
          color={iconColor}
        />
      )}
    </AntdButton>
  )
}

export default React.forwardRef<HTMLButtonElement, ButtonProps>(Button)

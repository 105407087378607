import { useMemo } from 'react'
import { Dropdown, Tabs } from 'antd'

import { OCRDocument } from 'domain/myFiles/types'
import { DocumentTabName } from 'domain/orderItem/types'
import { Icon, ViewPDF } from 'ui'

import { useExtractedSummaryAndSections } from '../services/useExtractedSummary'
import ViewExtractionReport from './ViewExtractionReport'
import { useGetOrderItem } from 'domain/orderItem/shared'
import { useDownload } from 'utils/export'
import useDownloadOcrTranscription from 'domain/myFiles/services/useDownloadOcrTranscription'
import { useActiveTab } from '../state'
import { ViewFileOCRContent } from 'domain/myFiles'

interface Props {
  fileUrl: string
  ocr: OCRDocument
  onChange: React.Dispatch<React.SetStateAction<DocumentTabName>>
  openAITab?: React.ReactNode
  reportTab?: React.ReactNode
}

export default function ViewCertificateTabs({ fileUrl, openAITab, reportTab, onChange }: Props) {
  const [extracted, sections] = useExtractedSummaryAndSections()
  const orderItem = useGetOrderItem()
  const [handleDownload] = useDownload()
  const { handleOcrDownload } = useDownloadOcrTranscription()
  const activeTab = useActiveTab()

  const tabItems = useMemo(() => {
    const items = []

    if (reportTab || sections) {
      items.push({
        label: 'Ficha',
        key: 'report',
        icon: <Icon name="file" />,
        children: reportTab ?? <ViewExtractionReport extracted={extracted} sections={sections} />,
      })
    }

    items.push({
      key: 'original',
      icon: <Icon name="pdf-file" />,
      label: 'Documento em PDF',
      children: (
        <>
          <div className="row flex flex-1">
            <ViewPDF fileUrl={fileUrl} />
          </div>
        </>
      ),
    })

    if (orderItem.ocr && orderItem.ocr.pages && orderItem.fileURL) {
      items.push({
        label: 'Transcrição',
        key: 'transcript',
        icon: <Icon name="file" />,
        children: <ViewFileOCRContent />,
      })
    }

    if (openAITab) {
      items.push({
        key: 'openAI',
        icon: <Icon name="robot" />,
        label: 'Extração de Dados',
        children: openAITab,
      })
    }

    return items
  }, [fileUrl, reportTab, openAITab, sections, orderItem])

  const downloadOptions = [
    {
      disabled: !orderItem?.fileURL,
      key: '0',
      label: 'Documento em pdf',
      onClick: () => {
        const downloadUrl = `/orders/${orderItem.id}/download`
        handleDownload(downloadUrl)
      },
    },
    {
      disable: orderItem?.ocr?.id,
      key: '2',
      label: 'Transcrição em .txt',
      onClick: () => handleOcrDownload(orderItem?.ocr?.id ?? 0, 'docx'),
    },
    {
      visible: orderItem?.ocr?.id,
      key: '3',
      label: 'Extração de dados',
      onClick: () => {
        const itemsId = orderItem?.ocr?.id || ''
        const fileName = `cbrdoc-IA-pedido-${orderItem.order.orderNumber}`
        handleDownload(`reports/ai-answers/xlsx?ocr_id=${itemsId}`, fileName)
      },
    },
  ]

  return (
    <div className="flex flex-1 relative ml-8 mr-4">
      <Tabs
        className="full-content-tabs w-full pb-0 pt-0 bg-white rounded-lg justify-start"
        tabBarStyle={{ marginLeft: '1.5rem', lineHeight: '14px' }}
        onChange={onChange as any}
        items={tabItems}
      />

      {activeTab === 'openAI' && (
        <Dropdown
          menu={{ items: downloadOptions }}
          className="absolute cursor-pointer right-3 top-2"
          dropdownRender={menu => <div className="dropdown-options-primary">{menu}</div>}
        >
          <div className="bg-primary gap-4 flex items-center p-2 px-3 text-white rounded-sm">
            <Icon name="download" /> <p className="leading-[15px]">Baixar</p> <Icon name="chevron-down" />
          </div>
        </Dropdown>
      )}
    </div>
  )
}

import ViewAIActions from './ViewAIActions'
import { OpenAIQuestions } from 'domain/openAI/enrich'
import { ViewCertificateTabs } from 'domain/orderItem/certificate'
import { ViewOrderLayout, useGetOrderItem } from 'domain/orderItem/shared'

export default function ViewAI() {
  const { explorerItem, ocr } = useGetOrderItem()
  const { aiModel, file } = explorerItem ?? {}

  if (!file) return null

  return (
    <ViewOrderLayout showSidebar downloadButton={<ViewAIActions />}>
      <ViewCertificateTabs
        ocr={ocr!}
        fileUrl={file.signedUrl}
        onChange={() => null}
        openAITab={<OpenAIQuestions questions={aiModel?.questions}></OpenAIQuestions>}
      />
    </ViewOrderLayout>
  )
}
